import { ListItemText, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { UseVideoPlayerInstance } from 'Hooks/Editor/useVideoPlayerInstance';
import EditorVideoDetection, { DetectionType } from 'Models/Videos/Detections/EditorVideoDetection';
import { ReactElement } from 'react';

interface EditorDetectionDetailProps {
	instance: UseVideoPlayerInstance;
	detection: EditorVideoDetection;
}

const EditorDetectionDetail = ({ instance, detection }: EditorDetectionDetailProps): ReactElement => {
	const brand = instance.brands.find((x) => x.id === detection.brandId);
	const brandGroup = instance.brandGroups.find((x) => x.id === brand?.groupId);
	const placement = instance.placements.find((x) => x.id === detection.placementId);
	const detectionName = `${brand?.name} - ${placement?.name} [ID: ${detection.id}]`;

	const getDetectionTypeText = (): string => {
		switch (detection.detectionType) {
			case DetectionType.Logo:
				return 'Logo';
			case DetectionType.Text:
				return 'Text';
			case DetectionType.Manual:
				return 'Manual';
		}
		return '';
	};

	const detectionTypeText = getDetectionTypeText();

	return (
		<Card>
			<CardContent>
				<Typography variant="h5" component="h3" title={detectionName}>
					{detectionName}
				</Typography>
				<Divider />
				<List>
					<ListItem>
						<ListItemText
							primary={
								<>
									Brand: <b>{brand?.name ?? 'Unknown'}</b>
								</>
							}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary={
								<>
									Brand Group: <b>{brandGroup?.name ?? 'Unknown'}</b>
								</>
							}
						/>
					</ListItem>
					<ListItem>
						<ListItemText primary="Placement:" secondary={placement?.name ?? 'Unknown'} />
					</ListItem>
					<ListItem>
						<ListItemText primary="Id:" secondary={detection.id} />
					</ListItem>
					<ListItem>
						<ListItemText primary="Track Id:" secondary={detection.trackId} />
					</ListItem>
					<ListItem>
						<ListItemText primary="Type:" secondary={detectionTypeText} />
					</ListItem>
					<ListItem>
						<ListItemText primary="Area:" secondary={`${Math.round(detection.area * 10000) / 100}%`} />
					</ListItem>
					<ListItem>
						<ListItemText primary="Angle:" secondary={`${detection.angle}°`} />
					</ListItem>
					<ListItem>
						<ListItemText
							primary="Initial position:"
							secondary={`[${Math.round(detection.initialCoordinates.point1.x)}, ${Math.round(
								detection.initialCoordinates.point1.y
							)}], [${Math.round(detection.initialCoordinates.point2.x)}, ${Math.round(
								detection.initialCoordinates.point2.y
							)}], [${Math.round(detection.initialCoordinates.point3.x)}, ${Math.round(
								detection.initialCoordinates.point3.y
							)}], [${Math.round(detection.initialCoordinates.point4.x)}, ${Math.round(
								detection.initialCoordinates.point4.y
							)}]`}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary="Initial center:"
							secondary={`[${Math.round(detection.initialCenter.x)}, ${Math.round(detection.initialCenter.y)}]`}
						/>
					</ListItem>
				</List>
			</CardContent>
		</Card>
	);
};

export default EditorDetectionDetail;

import Vector2 from 'Models/Vector2';
import { MutableRefObject, useCallback, useEffect } from 'react';

interface UseFillRectOptions {
	/**
	 * HTML colours
	 */
	colour?: string;
}

interface UseFillRectProps {
	point: Vector2;
	width: number;
	height: number;
	canvasRef: MutableRefObject<HTMLCanvasElement | null>;
	options?: UseFillRectOptions;
}

const DEFAULT_COLOUR = '#0000cc';

const useFillRect = (props: UseFillRectProps) => {
	const fillRect = useCallback(
		(ctx: CanvasRenderingContext2D): void => {
			ctx.fillStyle = props.options?.colour ?? DEFAULT_COLOUR;
			ctx.fillRect(props.point.x, props.point.y, props.width, props.height);
		},
		[props.height, props.options?.colour, props.point.x, props.point.y, props.width]
	);

	useEffect(() => {
		if (props.canvasRef.current === null) return;
		const ctx = props.canvasRef.current.getContext('2d');
		if (ctx === null) return;

		ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
		fillRect(ctx);
		ctx.restore();
	}, [fillRect, props.canvasRef]);
};

export default useFillRect;

import { TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { isArray } from '@material-ui/data-grid';
import { Autocomplete } from '@material-ui/lab';
import ChangeAllPlacementCard from 'Components/Editor/Details/ChangeAllPlacements/ChangeAllPlacementCard';
import ChangeAllPlacementCardBrandLogoSelect from 'Components/Editor/Details/ChangeAllPlacements/ChangeAllPlacementCardBrandLogoSelect';
import ChangeAllPlacementCardBrandTextSelect from 'Components/Editor/Details/ChangeAllPlacements/ChangeAllPlacementCardBrandTextSelect';
import Brand from 'Models/Brands/Brand';
import { ReactElement, useMemo } from 'react';

interface ChangeAllPlacementCardBrandsProps {
	brands: Brand[];
	selectedBrands: Brand[] | null;
	onSelectedBrandsChanged: (brands: Brand[] | null) => void;
	selectedTexts: number[] | null;
	onSelectedTextsChanged: (ids: number[] | null) => void;
	selectedLogos: number[] | null;
	onSelectedLogosChanged: (ids: number[] | null) => void;
}

const ChangeAllPlacementCardBrands = (props: ChangeAllPlacementCardBrandsProps): ReactElement => {
	const sortedBrands = useMemo(() => props.brands.sort((a, b) => a.name.localeCompare(b.name)), [props.brands]);
	const isChecked = props.selectedBrands !== null;
	const displayTextOrLogoSelect = props.selectedBrands !== null && props.selectedBrands.length === 1;
	const singleSelectedBrand = props.selectedBrands?.length === 1 ? props.selectedBrands[0] : null;

	const handleCheckedChanged = (checked: boolean): void => {
		if (checked) props.onSelectedBrandsChanged([]);
		else props.onSelectedBrandsChanged(null);

		props.onSelectedTextsChanged(null);
		props.onSelectedLogosChanged(null);
	};

	const handleBrandsSelectionChanged = (_: Object, brands: Brand | Brand[]): void => {
		if (isArray(brands)) {
			props.onSelectedBrandsChanged(brands);
		} else {
			console.error(`expected number array but instead got 'target.value'`);
		}
		props.onSelectedTextsChanged(null);
		props.onSelectedLogosChanged(null);
	};

	const handleSelectedTextIdsChanged = (ids: number[]): void => {
		if (ids.length === 0) props.onSelectedTextsChanged(null);
		props.onSelectedTextsChanged(ids);
	};

	const handleSelectedLogoIdsChanged = (ids: number[]): void => {
		if (ids.length === 0) props.onSelectedLogosChanged(null);
		props.onSelectedLogosChanged(ids);
	};

	return (
		<ChangeAllPlacementCard name={'Select brands'} onCheckedChanged={handleCheckedChanged} isChecked={isChecked}>
			<FormControl fullWidth={true} margin="normal">
				<Autocomplete
					multiple={true}
					renderInput={(params) => <TextField {...params} label="Brands" fullWidth={true} />}
					options={sortedBrands}
					fullWidth={true}
					getOptionLabel={(option) => option.name}
					autoHighlight={true}
					onChange={handleBrandsSelectionChanged}
					value={props.selectedBrands ?? []}
				/>
			</FormControl>
			<ChangeAllPlacementCardBrandTextSelect
				displayContent={displayTextOrLogoSelect}
				brand={singleSelectedBrand}
				selectedTextIds={props.selectedTexts ?? []}
				onSelectedTextIdsChanged={handleSelectedTextIdsChanged}
			/>
			<br />
			<ChangeAllPlacementCardBrandLogoSelect
				brand={singleSelectedBrand}
				onSelectedLogoIdsChanged={handleSelectedLogoIdsChanged}
				selectedLogoIds={props.selectedLogos ?? []}
				displayContent={displayTextOrLogoSelect}
			/>
		</ChangeAllPlacementCard>
	);
};

export default ChangeAllPlacementCardBrands;

import useFillRect from 'Hooks/Editor/useFillRect';
import { UseVideoPlayerInstance, VideoPlayerDetailsAreaMode } from 'Hooks/Editor/useVideoPlayerInstance';
import { MutableRefObject } from 'react';

const useDrawExamplePosition = (
	instance: UseVideoPlayerInstance,
	canvasRef: MutableRefObject<HTMLCanvasElement | null>,
	scale: number
): void => {
	const position = instance.exampleDetectionPosition;
	const point = position?.point ?? { x: 0, y: 0 };
	const scaledPoint = { x: point.x * scale, y: point.y * scale };
	const w = position?.offset.x ?? 0;
	const width = w > 0 ? w : 1;
	const scaledWidth = width * scale;
	const h = position?.offset.y ?? 0;
	const height = h > 0 ? h : 1;
	const scaledHeight = height * scale;

	const colour = 'rgba(00, 00, 204, 0.6';
	const options = {
		colour: colour,
	};

	const show = instance.detailsAreaMode === VideoPlayerDetailsAreaMode.CHANGE_ALL_PLACEMENTS;
	const useFillRectProps = show
		? { point: scaledPoint, width: scaledWidth, height: scaledHeight, canvasRef, options }
		: { point: { x: 0, y: 0 }, width: 0, height: 0, canvasRef, options }; // This is "hiding" the selection.
	useFillRect(useFillRectProps);
};

export default useDrawExamplePosition;

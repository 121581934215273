import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import ChangeAllPlacementCard from 'Components/Editor/Details/ChangeAllPlacements/ChangeAllPlacementCard';
import { UseVideoPlayerInstance } from 'Hooks/Editor/useVideoPlayerInstance';
import { DetectionPositionSpecification } from 'Models/Project/ProjectFilterSpecifications';
import { ChangeEvent, ReactElement } from 'react';

interface ChangeAllPlacementCardPositionProps {
	position: DetectionPositionSpecification | null;
	onPositionChange: (position: DetectionPositionSpecification | null) => void;
	instance: UseVideoPlayerInstance;
}

const INITIAL_POSITION_VALUE: DetectionPositionSpecification = {
	point: { x: 0, y: 0 },
	offset: { x: 0, y: 0 },
};

const ChangeAllPlacementCardPosition = (props: ChangeAllPlacementCardPositionProps): ReactElement => {
	const isChecked = props.position !== null;
	const x = props.position?.point.x ?? 0;
	const y = props.position?.point.y ?? 0;
	const offsetX = props.position?.offset?.x ?? 0; // Offset can be undefined due to legacy
	const offsetY = props.position?.offset?.y ?? 0; // Offset can be undefined due to legacy

	const videoSize = {
		width: props.instance.video?.width ?? Number.MAX_SAFE_INTEGER,
		height: props.instance.video?.height ?? Number.MAX_SAFE_INTEGER,
	};

	const handlePlacementCardCheckedChanged = (checked: boolean): void => {
		if (checked) props.onPositionChange(INITIAL_POSITION_VALUE);
		else props.onPositionChange(null);
	};

	const handleXChanged = ({ target }: ChangeEvent<HTMLInputElement>): void => {
		const value = Number(target.value);
		if (!isNaN(value)) props.onPositionChange({ point: { x: value, y }, offset: { x: offsetX, y: offsetY } });
	};

	const handleYChanged = ({ target }: ChangeEvent<HTMLInputElement>): void => {
		const value = Number(target.value);
		if (!isNaN(value)) props.onPositionChange({ point: { y: value, x }, offset: { x: offsetX, y: offsetY } });
	};

	const handleOffsetXChanged = ({ target }: ChangeEvent<HTMLInputElement>): void => {
		const value = Number(target.value);
		if (!isNaN(value)) props.onPositionChange({ point: { x, y }, offset: { x: value, y: offsetY } });
	};

	const handleOffsetYChanged = ({ target }: ChangeEvent<HTMLInputElement>): void => {
		const value = Number(target.value);
		if (!isNaN(value)) props.onPositionChange({ point: { x, y }, offset: { x: offsetX, y: value } });
	};

	return (
		<ChangeAllPlacementCard
			name="Select position"
			onCheckedChanged={handlePlacementCardCheckedChanged}
			isChecked={isChecked}
		>
			<FormControl fullWidth={true} margin="normal">
				<InputLabel required={true}>X</InputLabel>
				<Input
					onChange={handleXChanged}
					value={x}
					required={true}
					inputProps={{
						step: 1,
						min: 0,
						max: videoSize.width,
						type: 'number',
					}}
				/>
			</FormControl>
			<FormControl fullWidth={true} margin="normal">
				<InputLabel required={true}>Y</InputLabel>
				<Input
					onChange={handleYChanged}
					value={y}
					required={true}
					inputProps={{
						step: 1,
						min: 0,
						max: videoSize.height,
						type: 'number',
					}}
				/>
			</FormControl>
			<FormControl fullWidth={true} margin="normal">
				<InputLabel required={true}>X offset</InputLabel>
				<Input
					onChange={handleOffsetXChanged}
					value={offsetX}
					required={true}
					inputProps={{
						step: 1,
						min: 0,
						max: videoSize.width - x,
						type: 'number',
					}}
				/>
			</FormControl>
			<FormControl fullWidth={true} margin="normal">
				<InputLabel required={true}>Y offset</InputLabel>
				<Input
					onChange={handleOffsetYChanged}
					value={offsetY}
					required={true}
					inputProps={{
						step: 1,
						min: 0,
						max: videoSize.height - y,
						type: 'number',
					}}
				/>
			</FormControl>
		</ChangeAllPlacementCard>
	);
};

export default ChangeAllPlacementCardPosition;

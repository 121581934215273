import Brand from 'Models/Brands/Brand';
import {
	DetectionAngleSpecification,
	DetectionAreaSpecification,
	DetectionPositionSpecification,
	DetectionTimeSpecification,
} from 'Models/Project/ProjectFilterSpecifications';

const TOKEN_KEY = 'TOKEN';
const SELECTED_VIDEO_ID_KEY = 'SELECTED_VIDEO_ID';
const EDITOR_TIME = 'EDITOR_TIME';
const CHANGE_ALL_PLACEMENTS_PLACEMENT_IDS = 'CHANGE_ALL_PLACEMENTS_PLACEMENT_IDS';
const CHANGE_ALL_PLACEMENTS_BRANDS = 'CHANGE_ALL_PLACEMENTS_BRANDS';
const CHANGE_ALL_PLACEMENTS_TEXTS = 'CHANGE_ALL_PLACEMENTS_TEXTS';
const CHANGE_ALL_PLACEMENTS_LOGOS = 'CHANGE_ALL_PLACEMENTS_LOGOS';
const CHANGE_ALL_PLACEMENTS_ANGLE = 'CHANGE_ALL_PLACEMENTS_ANGLE';
const CHANGE_ALL_PLACEMENTS_AREA = 'CHANGE_ALL_PLACEMENTS_AREA';
const CHANGE_ALL_PLACEMENTS_TIME = 'CHANGE_ALL_PLACEMENTS_TIME';
const CHANGE_ALL_PLACEMENTS_POSITION = 'CHANGE_ALL_PLACEMENTS_POSITION';

export const clearStorage = (): void => localStorage.clear();

// Common function for storage
const getNumberValue = (key: string): number | null => {
	const value = localStorage.getItem(key) ?? '';
	const numberValue = parseInt(value);
	if (isNaN(numberValue)) return null;
	return numberValue;
};

// Token storage
export const setToken = (token: string): void => localStorage.setItem(TOKEN_KEY, token);
export const getToken = (): string | null => localStorage.getItem(TOKEN_KEY);
export const deleteToken = (): void => localStorage.removeItem(TOKEN_KEY);

// Video storage
export const setSelectedVideoId = (id: number): void => localStorage.setItem(SELECTED_VIDEO_ID_KEY, id.toString());
export const getSelectedVideoId = (): number | null => getNumberValue(SELECTED_VIDEO_ID_KEY);
export const deleteSelectedVideoId = (): void => localStorage.removeItem(SELECTED_VIDEO_ID_KEY);

// Editor time storage
export const setEditorTime = (time: number): void => localStorage.setItem(EDITOR_TIME, time.toString());
export const getEditorTime = (): number | null => getNumberValue(EDITOR_TIME);
export const deleteEditorTime = (): void => localStorage.removeItem(EDITOR_TIME);

// Change all placement filters
export const setChangeAllPlacementPlacementIds = (placementIds: number[] | null): void =>
	localStorage.setItem(CHANGE_ALL_PLACEMENTS_PLACEMENT_IDS, JSON.stringify(placementIds));
export const getChangeAllPlacementPlacementIds = (): number[] | null =>
	JSON.parse(localStorage.getItem(CHANGE_ALL_PLACEMENTS_PLACEMENT_IDS) ?? 'null');
export const deleteChangeAllPlacementPlacementIds = (): void =>
	localStorage.removeItem(CHANGE_ALL_PLACEMENTS_PLACEMENT_IDS);

// Change all placements, selected brands
export const setChangeAllPlacementBrands = (brands: Brand[] | null): void =>
	localStorage.setItem(CHANGE_ALL_PLACEMENTS_BRANDS, JSON.stringify(brands));
export const getChangeAllPlacementBrands = (): Brand[] | null =>
	JSON.parse(localStorage.getItem(CHANGE_ALL_PLACEMENTS_BRANDS) ?? 'null');
export const deleteChangeAllPlacementBrands = (): void => localStorage.removeItem(CHANGE_ALL_PLACEMENTS_BRANDS);

// Change all placements, selected texts
export const setChangeAllPlacementTextIds = (ids: number[] | null): void =>
	localStorage.setItem(CHANGE_ALL_PLACEMENTS_TEXTS, JSON.stringify(ids));
export const getChangeAllPlacementTextIds = (): number[] | null =>
	JSON.parse(localStorage.getItem(CHANGE_ALL_PLACEMENTS_TEXTS) ?? 'null');
export const deleteChangeAllPlacementTextIds = (): void => localStorage.removeItem(CHANGE_ALL_PLACEMENTS_TEXTS);

// Change all placements, selected logos
export const setChangeAllPlacementLogoIds = (ids: number[] | null): void =>
	localStorage.setItem(CHANGE_ALL_PLACEMENTS_LOGOS, JSON.stringify(ids));
export const getChangeAllPlacementLogoIds = (): number[] | null =>
	JSON.parse(localStorage.getItem(CHANGE_ALL_PLACEMENTS_LOGOS) ?? 'null');
export const deleteChangeAllPlacementLogoIds = (): void => localStorage.removeItem(CHANGE_ALL_PLACEMENTS_LOGOS);

export const setChangeAllPlacementAngle = (angle: DetectionAngleSpecification | null): void =>
	localStorage.setItem(CHANGE_ALL_PLACEMENTS_ANGLE, JSON.stringify(angle));
export const getChangeAllPlacementAngle = (): DetectionAngleSpecification | null =>
	JSON.parse(localStorage.getItem(CHANGE_ALL_PLACEMENTS_ANGLE) ?? 'null');
export const deleteChangeAllPlacementAngle = (): void => localStorage.removeItem(CHANGE_ALL_PLACEMENTS_ANGLE);

export const setChangeAllPlacementArea = (area: DetectionAreaSpecification | null): void =>
	localStorage.setItem(CHANGE_ALL_PLACEMENTS_AREA, JSON.stringify(area));
export const getChangeAllPlacementArea = (): DetectionAreaSpecification | null =>
	JSON.parse(localStorage.getItem(CHANGE_ALL_PLACEMENTS_AREA) ?? 'null');
export const deleteChangeAllPlacementArea = (): void => localStorage.removeItem(CHANGE_ALL_PLACEMENTS_AREA);

export const setChangeAllPlacementTime = (time: DetectionTimeSpecification | null): void =>
	localStorage.setItem(CHANGE_ALL_PLACEMENTS_TIME, JSON.stringify(time));
export const getChangeAllPlacementTime = (): DetectionTimeSpecification | null =>
	JSON.parse(localStorage.getItem(CHANGE_ALL_PLACEMENTS_TIME) ?? 'null');
export const deleteChangeAllPlacementTime = (): void => localStorage.removeItem(CHANGE_ALL_PLACEMENTS_TIME);

export const setChangeAllPlacementPosition = (position: DetectionPositionSpecification | null): void =>
	localStorage.setItem(CHANGE_ALL_PLACEMENTS_POSITION, JSON.stringify(position));
export const getChangeAllPlacementPosition = (): DetectionPositionSpecification | null =>
	JSON.parse(localStorage.getItem(CHANGE_ALL_PLACEMENTS_POSITION) ?? 'null');
export const deleteChangeAllPlacementPosition = (): void => localStorage.removeItem(CHANGE_ALL_PLACEMENTS_POSITION);
